import React from 'react'
import Head from 'next/head'
import { GetServerSideProps } from 'next'

import { PublicUser } from 'services/apiTypes/user.types'

import userService, { UserService } from 'services/user.service'
import CookieService from 'services/cookie.service'

import PageWrapper from 'components/misc/PageWrapper'
import CardBanner from 'components/misc/CardBanner'
import InlineAd from 'components/advertisments/InlineAd'
import DeckLink, { gridClass } from 'components/misc/DeckLink'
import FolderList from 'components/userPage/FolderList'
import UserList from 'components/userPage/UserList'
import UserInfoAndBioCard from 'components/userPage/UserInfoAndBioCard'
import Header from 'components/userPage/Header'

import { deltaToString } from 'utils/Deltas'
import { LOG_ERROR_AND_REDIRECT } from 'utils/ErrorString'
import { decodeUsernameRoute } from 'utils/encoding'

import styles from './username.module.scss'

type Props = { user: PublicUser }

const UserPage = ({ user }: Props) => {
  const bannerSrc = user.decks[0]?.customFeatured || user.decks[0]?.featured || '/images/hex_background.jpg'
  const bio = deltaToString(user.profile.bio)
  const title = `${user.username} - Archidekt`

  return (
    <PageWrapper title={title} description={bio} className="AccountSettingsPage">
      <Head>
        <meta property="og:type" content="website" />
        <meta property="og:image" content={user.profile.avatar} />
        <meta property="og:image:width" content="100" />
        <meta property="og:image:height" content="100" />
        <meta property="og:image:type" content="image/jpeg" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:label1" content="Decks" />
        <meta name="twitter:data1" content={`${user.deckCount}`} />
        <meta name="twitter:label2" content="Comments" />
        <meta name="twitter:data2" content={`${user.commentCount}`} />
      </Head>

      <CardBanner src={bannerSrc} allowOverflow height="250px" />

      <div className={styles.container}>
        <UserInfoAndBioCard user={user} className={styles.containWidth} />

        <InlineAd removeOnNoAd id="user-page-one" banner longBanner billboard allowTallMobile />

        <div className={styles.containWidth}>
          <Header
            main="Recent Decks"
            secondary="View all decks"
            href={`/search/decks/?ownerUsername=${user.username}`}
          />
          <div className={gridClass}>
            {user.decks.map((deck, index) => (
              <DeckLink key={index} deck={deck} />
            ))}
          </div>

          {!user.decks.length && <div className={styles.noDecks}>{user.username} has no public decks</div>}
        </div>

        <FolderList folderId={user.rootFolderId} className={styles.containWidth} />

        <InlineAd removeOnNoAd id="user-page-two" banner longBanner billboard allowTallMobile />

        <UserList
          header="Followers"
          fetchUsers={(next?: string) => userService.followers(user.id, next)}
          className={styles.containWidth}
        />
        <UserList
          header="Following"
          fetchUsers={(next?: string) => userService.following(user.id, next)}
          className={styles.containWidth}
        />
      </div>
    </PageWrapper>
  )
}

export default UserPage

export const getServerSideProps: GetServerSideProps<Props> = async context => {
  try {
    const username = decodeUsernameRoute(`${context.params?.username}`)
    const user = await new UserService(context).detailsByUsername(username)

    if (user.referrerEnum) CookieService.setServerCookie({ 'referred-by': user.referrerEnum }, context, 60 * 60 * 24)

    return { props: { user } }
  } catch (error) {
    return LOG_ERROR_AND_REDIRECT(error, context)
  }
}
